<template>
    <div class="app-container">
        <div class="search-container">
            <div class="search-tree">
                <div class="search-label">选择查询部门</div>
                <div class="search-value">
                    <el-tree :data="schoolWithShops" show-checkbox default-expand-all node-key="treeId" @check-change="treeChange"></el-tree>
                </div>
            </div>
            <div class="search-options">
                <div class="search-item">
                    <div class="search-label">查询日期</div>

                    <el-date-picker v-model="dateArr" type="datetimerange" align="right" value-format="yyyy-MM-dd HH:mm:ss" unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :default-time="['00:00:00', '23:59:59']" size="small" class="filter-item" style="display: inline-flex;" clearable></el-date-picker>
                </div>

                <div class="search-item" style="display: flex;">
                    <el-button type="primary" size="small" @click="handleFilter" style="margin-right: 20px;">搜索</el-button>

                    <download-excel :fields="json_fields" :fetch="handleExport" type="xls" name="现场钱包对账.xls" worksheet="现场钱包对账">
                        <el-button type="success" size="small" :loading="exportLoading">导出</el-button>
                    </download-excel>
                </div>
            </div>
        </div>

        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column label="店铺ID" width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column label="店铺名称" min-width="110" align="center">
                <template slot-scope="scope">
                    <div>{{ scope.row.shop_name }}</div>
                </template>
            </el-table-column>

            <el-table-column label="交易笔数" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.pay_count }}
                </template>
            </el-table-column>

            <el-table-column label="金额" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.pay_money }}
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";
    import downloadExcel from "vue-json-excel";
    import { guid } from "@/utils/index";

    export default {
        components: { downloadExcel },
        data() {
            return {
                list: null,
                listLoading: false,
                listQuery: {
                    shop_ids: [],
                    start_time: '',
                    end_time: '',
                },
                shops: [],
                dateArr: null,
                schoolWithShops: [],
                treeIds: [],
                // 导出现场钱包对账
                exportLoading: false,
                json_fields: {
                    店铺ID: "id",
                    店铺名称: "shop_name",
                    交易笔数: {
                        field: "pay_count",
                        //自定义回调函数
                        callback: (value) => {
                            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
                        },
                    },
                    金额: {
                        field: "pay_money",
                        //自定义回调函数
                        callback: (value) => {
                            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
                        },
                    },
                },
            };
        },
        created() {
            this.getSchoolWithShop();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"])
        },
        watch: {
            school_id(newVal, oldVal) {
                this.getSchoolWithShop();
            },
            dateArr(newVal, oldVal) {
                if (newVal) {
                    this.listQuery.start_time = newVal[0];
                    this.listQuery.end_time = newVal[1];
                } else {
                    this.listQuery.start_time = "";
                    this.listQuery.end_time = "";
                }
            },
        },
        methods: {
            getList() {
                let shop_ids = [];
                this.schoolWithShops.forEach(element => {
                    element.children.forEach(element2 => {
                        element2.children.forEach(element3 => {
                            if (this.treeIds.indexOf(element3.treeId) > -1) {
                                shop_ids.push(element3.id);
                            }
                        });
                    });
                });
                this.listQuery.shop_ids = shop_ids;
                if (this.listQuery.shop_ids.length <= 0) {
                    this.$message({
                        type: "warning",
                        message: "请选择店铺"
                    });
                    return;
                }
                if (!this.listQuery.start_time || !this.listQuery.end_time) {
                    this.$message({
                        type: "warning",
                        message: "请选择时间段"
                    });
                    return;
                }

                this.listLoading = true;
                request({
                    url: "/api/backend/finance/liveWalletReport",
                    method: "post",
                    data: this.listQuery
                }).then(response => {
                    this.list = response.data;
                    this.listLoading = false;
                });
            },
            getSchoolWithShop() {
                if (!this.school_id) {
                    return;
                }
                request({
                    url: "/api/backend/school/schoolWithShop",
                    method: "get",
                    params: {
                        school_id: this.school_id
                    }
                }).then(response => {
                    let data = response.data;
                    let schoolWithShops = [];
                    data.forEach(element => {
                        let obj = {
                            treeId: guid(),
                            id: element.id,
                            label: element.school_name,
                            children: []
                        };
                        element.canteens.forEach(element2 => {
                            let obj2 = {
                                treeId: guid(),
                                id: element2.id,
                                label: element2.canteen_name,
                                children: []
                            };
                            element2.shops.forEach(element3 => {
                                let obj3 = {
                                    treeId: guid(),
                                    id: element3.id,
                                    label: element3.shop_name,
                                    children: []
                                };
                                obj2.children.push(obj3);
                            });
                            obj.children.push(obj2);
                        });
                        schoolWithShops.push(obj);
                    });
                    this.schoolWithShops = schoolWithShops;
                });
            },
            treeChange(target, currentCheck, childCheck) {
                let index = this.treeIds.indexOf(target.treeId);
                if (currentCheck) {
                    if (index < 0) {
                        this.treeIds.push(target.treeId);
                    }
                } else {
                    if (index > -1) {
                        this.treeIds.splice(index, 1);
                    }
                }
            },
            handleFilter() {
                this.getList();
            },
            // 导出现场钱包对账
            handleExport() {
                let shop_ids = [];
                this.schoolWithShops.forEach(element => {
                    element.children.forEach(element2 => {
                        element2.children.forEach(element3 => {
                            if (this.treeIds.indexOf(element3.treeId) > -1) {
                                shop_ids.push(element3.id);
                            }
                        });
                    });
                });
                this.listQuery.shop_ids = shop_ids;
                if (this.listQuery.shop_ids.length <= 0) {
                    this.$message({
                        type: "warning",
                        message: "请选择店铺"
                    });
                    return;
                }
                if (!this.listQuery.start_time || !this.listQuery.end_time) {
                    this.$message({
                        type: "warning",
                        message: "请选择时间段"
                    });
                    return;
                }

                let listQuery = JSON.parse(JSON.stringify(this.listQuery))
                this.exportLoading=true;
                return request({
                    url: "/api/backend/finance/liveWalletReport",
                    method: "post",
                    data: listQuery,
                    timeout: 10 * 60 * 1000,
                }).then((result) => {
                    if (result.data.length <= 0) {
                        this.$message({
                            type: "warning",
                            message: "暂无记录",
                        });
                        return;
                    }
                    var json_data = result.data;
                    return json_data;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.exportLoading=false;
                });
            },
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
    .search-container {
        display: flex;
        min-height: 380px;
        padding-bottom: 10px;
        box-sizing: border-box;
        margin-bottom: 30px;
        /*border-bottom: 1px solid #f0f0f0;*/
        .search-tree {
            flex: 0 0 auto;
            width: 300px;
            margin-left: 40px;
            margin-right: 20px;
            .search-label {
                font-size: 14px;
                color: #606266;
                font-weight: bold;
                margin-bottom: 8px;
            }
            .search-value {
                height: 510px;
                overflow: auto;
            }
            ::-webkit-scrollbar{
                width: 5px;
                height: 5px;
            }
            //定义滑块 内阴影+圆角
            ::-webkit-scrollbar-thumb{
                border-radius: 1em;
                background-color: rgba(50,50,50,.3);
            }
            //定义滚动条轨道 内阴影+圆角
            ::-webkit-scrollbar-track{
                border-radius: 1em;
                background-color: rgba(50,50,50,.1);
            }
        }

        .search-options {
            width: 300px;
            flex: 1 0 auto;
            font-size: 14px;
            font-weight: bold;
            color: #606266;
            margin-left: 10px;
            .search-item {
                margin-bottom: 22px;
                .search-label {
                    margin-bottom: 8px;
                }
            }
        }
    }

    .report-container {
        width: 800px;
        margin: 0 auto;
        background: #f9f9f9;
        padding: 20px 0;
        color: #414141;
        position: relative;
        .options {
            display: flex;
            justify-content: flex-end;
            .btn-print {
                margin-right: 8px;
            }
        }

        .report-content {
            padding: 25px 25px 0 25px;
            box-sizing: border-box;
            position: relative;
            overflow: hidden;
            .water-mark {
                width: 160%;
                position: absolute;
                top: 0;
                left: -30%;
                z-index: 100;
                color: #67aef7;
                font-size: 16px;
                line-height: 240px;
                transform: rotate(-45deg) translateY(-100px);
                .mark-name {
                    display: inline-block;
                    margin-left: 50px;
                }
            }
            .report-header {
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                color: #414141;
                margin-bottom: 20px;
                .report-header-date {
                    margin-top: 6px;
                }
            }
            .report-label {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                font-size: 14px;
                color: #414141;
                .report-label-right {
                    min-width: 160px;
                }
            }
            .report-foot {
                margin-top: 10px;
                .foot-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 4px 0;
                    .foot-label,
                    .foot-value {
                        min-width: 160px;
                        font-size: 14px;
                        color: #414141;
                    }
                }
            }
        }
        .divide-line {
            width: 100%;
            height: 1px;
            background-image: linear-gradient(to right, #414141 0%, #414141 50%, transparent 50%);
            background-size: 8px 1px;
            background-repeat: repeat-x;
            margin-top: 30px;
        }
    }

    #report * {
        font-size: 18px !important;
    }

    .report-table {
        background: #fff;
        border-collapse: collapse;
    }
    .report-table td, .report-table th {
        border: 1px solid #d2d2d2;
    }
    .report-table th {
        font-weight: normal;
    }
    .report-table th, .report-table td {
        width: 11%;
        text-align: center;
        padding: 8px;
    }
</style>
